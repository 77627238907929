'use strict';

angular.module('enervexSalesappApp').controller('ContactCtrl', function($scope, $stateParams, $state, Contact, Account) {
	$scope.accountId = $stateParams.accountId;
	$scope.contactId = $stateParams.contactId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	function getContact() {
		Contact.get({
			id: $stateParams.contactId,
			accountId: $stateParams.accountId
		}).$promise.then(function(contactInformation) {
			$scope.contactInformation = contactInformation;
		});
	}
	if ($stateParams.contactId != 'new'){
		getContact();
	}
	$scope.updateContact = function(contactInformation) {
		Contact.update({
			id: $stateParams.contactId,
			accountId: $stateParams.accountId
		}, contactInformation).$promise.then(function() {
			$state.go('contacts', {
				accountId: $scope.accountId,
			}, {
				inherit: false,
				reload: true
			});
		});
	}
	$scope.addContact = function(contactInformation) {
		Contact.save({
			accountId: $stateParams.accountId
		}, contactInformation).$promise.then(function(res){
			$state.go('contacts', {
				accountId: $scope.accountId,
			}, {
				inherit: false,
				reload: true
			});
		});
	}
});
